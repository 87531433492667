import { useCallback, useEffect } from "react"
import "vanilla-cookieconsent"
import "vanilla-cookieconsent/dist/cookieconsent.css"
import { cookiesConfig } from "../../../utils/config"

const CookieConsent = () => {
  const updateGaScripts = useCallback((consent, preferencesChanged) => {
    if (process.env.NODE_ENV === "production") {
      if (consent.categories.includes("analytics")) {
        insertGaScripts()
      } else {
        removeGaScripts()
        // Sometimes, the "ga-2" cookie is left over even on denial of consent for it. The following is an escape hatch to check if it exists without consent and remove it.
        window.CC.eraseCookies([
          cookiesConfig.find((cookie) => cookie.id === "ga-2").name,
        ])
        // When cookie preferences are changed by the user and consent for analytics cookies has been freshly withdrawn, the "ga-2" cookie has a tendency to show up again after some time, unless the page has been reloaded. Therefore, we nudge the user to reload the page in such a case.
        if (preferencesChanged) {
          alert("Your changes will take effect once you reload the page.")
        }
      }
    }
  }, [])

  useEffect(() => {
    if (!document.getElementById("cc--main")) {
      window.CC = window.initCookieConsent()
      window.CC.run({
        current_lang: "en",
        autoclear_cookies: true,
        cookie_name: cookiesConfig.find((cookie) => cookie.isConsentCookie)
          .name,
        onAccept: function (consent) {
          updateGaScripts(consent)
        },
        onChange: function (consent, changed_preferences) {
          if (changed_preferences.includes("analytics")) {
            updateGaScripts(consent, true)
          }
        },
        gui_options: {
          consent_modal: {
            layout: "cloud",
            position: "bottom center",
            transition: "slide",
          },
        },
        languages: {
          en: {
            consent_modal: {
              title: "We use cookies!",
              description:
                "Hi, this website uses essential cookies to ensure its proper operation and analytics cookies to understand how you interact with it. The latter will be set only after consent. <button type='button' data-cc='c-settings' class='cc-link'>Let me choose</button>",
              primary_btn: {
                text: "Accept all",
                role: "accept_all",
              },
              secondary_btn: {
                text: "Reject all",
                role: "accept_necessary",
              },
            },
            settings_modal: {
              title: "Cookie preferences",
              save_settings_btn: "Save settings",
              accept_all_btn: "Accept all",
              reject_all_btn: "Reject all",
              close_btn_label: "Close",
              cookie_table_headers: [
                { col1: "Name/Source" },
                { col2: "Provider" },
                { col3: "Purpose" },
                { col4: "More Info" },
              ],
              blocks: [
                {
                  title: "Cookie usage 📢",
                  description:
                    "We use cookies to ensure the basic functionalities of the website and to enhance your browsing experience. You can choose for each category to opt-in/out whenever you want. For more details relative to our usage of cookies, please read the full <a href='/cookie' class='cc-link'>cookie policy</a>.",
                },
                {
                  title: "Strictly necessary cookies",
                  description:
                    "These cookies are essential for the proper functioning of the website.",
                  toggle: {
                    value: "necessary",
                    enabled: true,
                    readonly: true,
                  },
                  cookie_table: cookiesConfig
                    .filter((cookie) => cookie.category === "necessary")
                    .map((cookie) => ({
                      col1: cookie.name,
                      col2: cookie.provider,
                      col3: cookie.purpose,
                      col4: cookie.infoLink
                        ? `<a href="${cookie.infoLink.url}" target="_blank" rel="noreferrer">${cookie.infoLink.text} &#8599;</a>`
                        : "",
                    })),
                },
                {
                  title: "Performance and analytics cookies",
                  description:
                    "These cookies collect information about generic website events such as page loads, link clicks, scrolls, etc. All of the data is anonymised and cannot be used to identify you.",
                  toggle: {
                    value: "analytics",
                    enabled: false,
                    readonly: false,
                  },
                  cookie_table: cookiesConfig
                    .filter((cookie) => cookie.category === "analytics")
                    .map((cookie) => ({
                      col1: cookie.name,
                      col2: cookie.provider,
                      col3: cookie.purpose,
                      col4: "",
                    })),
                },
                {
                  title: "More information",
                  description:
                    'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="/contact">contact us</a>.',
                },
              ],
            },
          },
        },
      })
    }
  }, [updateGaScripts])

  const insertGaScripts = () => {
    if (!document.getElementById("ga-1")) {
      // Create ga script 1
      const gaScript1El = document.createElement("script")
      gaScript1El.id = "ga-1"
      // In HTML, dynamic scripts behave as "async" by default, so no need to set async attribute here.
      gaScript1El.src = `https://www.googletagmanager.com/gtag/js?id=G-${process.env.REACT_APP_GOOGLE_ANALYTICS_CONTAINER_ID}`
      // Insert
      document.head.insertBefore(gaScript1El, document.head.firstChild)
    }
    if (!document.getElementById("ga-2")) {
      // Create ga script 2
      const gaScript2Html = `<script id="ga-2">
        window.dataLayer = window.dataLayer || []
        function gtag() { dataLayer.push(arguments) }
        gtag("js", new Date())
        gtag("config", "G-${process.env.REACT_APP_GOOGLE_ANALYTICS_CONTAINER_ID}")
      </script>`
      const gaScript2El = document
        .createRange()
        .createContextualFragment(gaScript2Html)
      // Insert
      document.getElementById("ga-1").after(gaScript2El)
    }
  }

  const removeGaScripts = () => {
    if (document.getElementById("ga-1")) {
      document.getElementById("ga-1").remove()
    }
    if (document.getElementById("ga-2")) {
      document.getElementById("ga-2").remove()
    }
  }

  return null
}

export default CookieConsent
