import { Fragment } from "react"

const Contact = () => {
  // Use a comfortable value of the iframe height attribute such that a scrollbar is not introduced on the page by the iframe on any screen size.
  return (
    <Fragment>
      <div className="text-center pt-5 bg-gray-200">
        <div
          className="w-full"
          dangerouslySetInnerHTML={{
            __html: `
              <iframe
                title="SysRestart Contact"
                src="https://docs.google.com/forms/d/e/1FAIpQLSdN6R9Ct2bBNKAjeICfqgggARvNszlJspW-eHEX06qz6GEgSg/viewform?embedded=true"
                width="100%"
                height="1100px"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
                srcdoc="<div style='display: flex; justify-content: center; margin-top: 250px'><img src='https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.11.3/images/loading.gif' alt='Loading ...' /></div"
                onload="this.removeAttribute('srcdoc')"
              >
                Loading ...
              </iframe>
            `,
          }}
        />
      </div>
    </Fragment>
  )
}

export default Contact
