import { Fragment } from "react"
import { ImNewTab } from "react-icons/im"
import { legalDocsDate, productsCompliance } from "../../../utils/config"
import { ScrollResetterLink } from "../../../utils/functions"

const ProductsCompliance = () => {
  const ProductsComplianceTable = () => (
    <div className="overflow-x-auto">
      <table className="border-separate border border-slate-500">
        <thead>
          <tr>
            <th className="border border-slate-700 p-1 col-span-1">Product</th>
            <th className="border border-slate-700 p-1 col-span-1">
              Sub-product
            </th>
            <th className="border border-slate-700 p-1 col-span-1">Link</th>
            <th className="border border-slate-700 p-1 col-span-1">
              Compliant
            </th>
            <th className="border border-slate-700 p-1 col-span-1">Reason</th>
          </tr>
        </thead>
        <tbody>
          {productsCompliance.map((product, i) => (
            <Fragment key={i}>
              {!product.subProducts && (
                <tr>
                  <td className="border border-slate-700 p-1">
                    {product.name}
                  </td>
                  <td className="border border-slate-700 p-1"></td>
                  <td className="border border-slate-700 p-1">
                    <a
                      href={product.url}
                      target="_blank"
                      className="block text-blue-600"
                      rel="noreferrer"
                    >
                      <ImNewTab />
                    </a>
                  </td>
                  <td className="border border-slate-700 p-1">
                    {product.compliant ? "Yes" : "No"}
                  </td>
                  <td className="border border-slate-700 p-1">
                    {product.reason}
                  </td>
                </tr>
              )}
              {product.subProducts &&
                product.subProducts.map((subProduct, j) => (
                  <tr key={j}>
                    {j === 0 ? (
                      <td className="border border-slate-700 p-1">
                        {product.name}
                      </td>
                    ) : (
                      <td className="border border-slate-700 p-1"></td>
                    )}
                    <td className="border border-slate-700 p-1">
                      {subProduct.name}
                    </td>
                    <td className="border border-slate-700 p-1">
                      <a
                        href={subProduct.url}
                        target="_blank"
                        className="inline-block text-blue-600"
                        rel="noreferrer"
                      >
                        <ImNewTab />
                      </a>
                    </td>
                    <td className="border border-slate-700 p-1">
                      {subProduct.compliant ? "Yes" : "No"}
                    </td>
                    <td className="border border-slate-700 p-1">
                      {subProduct.reason}
                    </td>
                  </tr>
                ))}
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )

  return (
    <div className="py-20 px-10 bg-gray-100">
      <h1 className="text-center text-3xl">
        Product-wise Compliance with Data Protection Laws
      </h1>
      <br />
      <br />
      <div className="text-justify">
        <p>
          <b>Last Updated Date: {legalDocsDate}</b>
        </p>
        <br />
        <p>
          Although we try our best to protect your privacy and ensure the
          security of your personal data when you use our products, some of our
          products are not fully compliant with the General Data Protection
          Regulation (GDPR) and other applicable data protection laws. This is
          due to the nature of the technologies they are built on.
        </p>
        <br />
        <p>
          <b>Product-wise Compliance</b>
        </p>
        <br />
        <p>
          The following enlists our products, whether they are compliant with
          the General Data Protection Regulation (GDPR) and other applicable
          data protection laws, and if not, the reason for the same:
        </p>
        <br />
        <ProductsComplianceTable />
        <br />
        <p>
          <b>Changes to this Document</b>
        </p>
        <br />
        <p>
          We may update this document from time to time to reflect changes in
          our practices or for other operational, legal, or regulatory reasons.
          We will post the updated document on this page and update the "Last
          Updated Date" accordingly. We encourage you to review this document
          periodically for any updates.
        </p>
        <br />
        <p>
          <b>Contact Us</b>
        </p>
        <br />
        <p>
          If you have any questions or concerns regarding this document, please{" "}
          <ScrollResetterLink to="/contact" className="text-blue-600">
            <u>contact us</u>
          </ScrollResetterLink>
          .
        </p>
      </div>
    </div>
  )
}

export default ProductsCompliance
