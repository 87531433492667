import { legalDocsDate } from "../../../utils/config"
import { ScrollResetterLink } from "../../../utils/functions"

const Privacy = () => (
  <div className="py-20 px-10 bg-gray-100">
    <h1 className="text-center text-3xl">Privacy Policy</h1>
    <br />
    <br />
    <div className="text-justify">
      <p>
        <b>Effective Date: {legalDocsDate}</b>
      </p>
      <br />
      <p>
        At SysRestart ("we," "us," "our," or "SysRestart"), we are committed to
        protecting your privacy and ensuring the security of your personal data
        when you use our products/sub-products ("products"). This privacy policy
        outlines how we collect, use, disclose, and safeguard your personal
        information.{" "}
        <ScrollResetterLink to="/products-compliance" className="text-blue-600">
          <u>Depending on the product</u>
        </ScrollResetterLink>
        , the General Data Protection Regulation (GDPR) and other simliar data
        protection laws may be applicable. In products where such laws are
        applicable, the the accompanying Cookie Policy is also applicable.
      </p>
      <br />
      <p>
        <b>Information We Collect</b>
      </p>
      <br />
      <p>
        We may collect various types of personal information when you use our
        products. This information may include, but is not limited to:
      </p>
      <ul className="list-disc list-inside">
        <li>Name</li>
        <li>Email address</li>
        <li>Profile picture</li>
        <li>IP address</li>
        <li>Geolocation data</li>
        <li>Device information</li>
        <li>Cookies and similar technologies data</li>
        <li>Demographic information</li>
        <li>Any other information you voluntarily provide to us</li>
      </ul>
      <br />
      <p>
        <b>How We Use Your Information</b>
      </p>
      <br />
      <p>We use your personal information for the following purposes:</p>
      <ul className="list-disc list-inside">
        <li>To provide and improve services of our products.</li>
        <li>
          To communicate with you regarding your account, services, and updates.
        </li>
        <li>To process and fulfill your orders and requests.</li>
        <li>To personalize and enhance your experience on our products.</li>
        <li>
          To conduct research and analyze user behavior to improve our
          offerings.
        </li>
        <li>
          To send you marketing and promotional materials (where permitted by
          law) based on your preferences.
        </li>
        <li>
          To comply with legal obligations and protect our rights and interests.
        </li>
      </ul>
      <br />
      <p>
        <b>Legal Basis for Processing</b>
      </p>
      <br />
      <p>Our legal basis for processing your personal data includes:</p>
      <ul className="list-disc list-inside">
        <li>The performance of a contract with you.</li>
        <li>Your consent for specific processing activities.</li>
        <li>Compliance with legal obligations.</li>
        <li>
          Legitimate interests pursued by us or third parties (e.g., fraud
          prevention, network and information security, and direct marketing).
        </li>
      </ul>
      <br />
      <p>
        <b>Data Retention</b>
      </p>
      <br />
      <p>
        We will retain your personal information for as long as necessary to
        fulfill the purposes outlined in this privacy policy, unless a longer
        retention period is required or permitted by law.
      </p>
      <br />
      <p>
        <b>Sharing Your Information</b>
      </p>
      <br />
      <p>We may share your personal data with the following third parties:</p>
      <ul className="list-disc list-inside">
        <li>
          Service providers and business partners who assist us in providing the
          services you requested.
        </li>
        <li>
          Legal and regulatory authorities if required to comply with applicable
          laws or protect our legal rights.
        </li>
        <li>
          Third parties in connection with a merger, acquisition, or sale of all
          or part of our business.
        </li>
        <li>
          With your consent or as otherwise disclosed at the time of data
          collection.
        </li>
      </ul>
      <br />
      <p>
        <b>Security</b>
      </p>
      <br />
      <p>
        We employ appropriate technical and organizational security measures at
        all times to protect the information we collect from you. We use
        multiple electronic, procedural, and physical security measures to
        protect against unauthorized or unlawful use or alteration of
        information, and against any accidental loss, destruction, or damage to
        information.
      </p>
      <br />
      <p>
        <b>International Data Transfers</b>
        <br />
        <em className="text-sm">
          Applicable for{" "}
          <ScrollResetterLink
            to="/products-compliance"
            className="text-blue-600"
          >
            <u>certain products</u>
          </ScrollResetterLink>
        </em>
      </p>
      <br />
      <p>
        We may transfer your personal information to countries outside the
        European Economic Area (EEA) that may not provide the same level of data
        protection. When we do so, we will ensure that appropriate safeguards
        are in place to protect your data as required by the GDPR and other
        simliar data protection laws.
      </p>
      <br />
      <p>
        <b>Your Rights</b>
        <br />
        <em className="text-sm">
          Applicable for{" "}
          <ScrollResetterLink
            to="/products-compliance"
            className="text-blue-600"
          >
            <u>certain products</u>
          </ScrollResetterLink>
        </em>
      </p>
      <br />
      <p>
        Under the GDPR and other simliar data protection laws, you have certain
        rights regarding your personal data. These rights include the right to:
      </p>
      <ul className="list-disc list-inside">
        <li>Access and obtain a copy of your personal data.</li>
        <li>Correct inaccurate or incomplete information.</li>
        <li>Withdraw your consent for processing (where applicable).</li>
        <li>Erase your personal data under certain circumstances.</li>
        <li>Restrict the processing of your data under specific conditions.</li>
        <li>
          Object to the processing of your data, including for direct marketing
          purposes.
        </li>
        <li>
          Receive your data in a structured, commonly used, and machine-readable
          format.
        </li>
        <li>Lodge a complaint with the relevant supervisory authority.</li>
      </ul>
      <br />
      <p>
        However, do note that no method of transmission over the internet, or
        method of electronic storage, is 100% secure. Therefore, we cannot
        guarantee its absolute security. Further, you are responsible for
        maintaining the confidentiality and security of your account
        credentials, and should not provide these credentials to any third
        party.
      </p>
      <br />
      <p>
        <b>Changes to this Privacy Policy</b>
      </p>
      <br />
      <p>
        We may update this privacy policy from time to time to reflect changes
        in our practices or for other operational, legal, or regulatory reasons.
        We will post the updated policy on this page and update the "Effective
        Date" accordingly. We encourage you to review this policy periodically
        for any updates.
      </p>
      <br />
      <p>
        <b>Contact Us</b>
      </p>
      <br />
      <p>
        If you have any questions or concerns regarding this privacy policy or
        the processing of your personal data, please{" "}
        <ScrollResetterLink to="/contact" className="text-blue-600">
          <u>contact us</u>
        </ScrollResetterLink>
        .
      </p>
      <br />
      <p>
        This privacy policy is effective as of the date mentioned above and is
        governed by the laws of Karnataka, India.
      </p>
    </div>
  </div>
)

export default Privacy
