import {
  FaLinkedinIn,
  FaGithub,
  FaStackOverflow,
  FaGlobe,
} from "react-icons/fa"
import { ImNpm, ImDownload } from "react-icons/im"
import { SiVisualstudiocode } from "react-icons/si"

const ProfileCard = ({
  name,
  description,
  imgSrc,
  imgRounded,
  stat,
  websiteUrl,
  linkedinUrl,
  githubUrl,
  stackoverflowUrl,
  producthuntEmbed,
  npmUrl,
  vsmUrl,
}) => (
  <div className="relative rounded-xl p-4 h-full bg-gray-50 flex flex-col items-center shadow-xl">
    {stat && (
      <span className="flex items-center absolute -right-4 -top-2 rounded-full text-xs mr-2 px-2 pt-0.5 pb-1 bg-gray-700 text-gray-300">
        <span className="text-xs">{stat}</span>
        <ImDownload className="ml-2" />
      </span>
    )}
    <img
      className={`inline-block h-20 ${
        imgRounded === false ? "rounded-none" : "rounded-full"
      }`}
      src={imgSrc}
      alt={name}
    />
    {name || description ? (
      <div className="my-3">
        {name && <p className="text-md font-semibold">{name}</p>}
        {description && (
          <p className="mt-2 text-sm text-gray-500 italic">{description}</p>
        )}
      </div>
    ) : (
      <div className="mt-1"></div>
    )}
    {producthuntEmbed && <div className="mb-3">{producthuntEmbed}</div>}
    <div className="mt-auto pt-3 w-20 border-t-2 flex justify-around object-center">
      {websiteUrl && (
        <a
          href={websiteUrl}
          target="_blank"
          className="inline-block text-blue-600"
          rel="noreferrer"
        >
          <FaGlobe />
        </a>
      )}
      {linkedinUrl && (
        <a
          href={linkedinUrl}
          target="_blank"
          className="inline-block text-blue-600"
          rel="noreferrer"
        >
          <FaLinkedinIn />
        </a>
      )}
      {githubUrl && (
        <a
          href={githubUrl}
          target="_blank"
          className="inline-block text-blue-600"
          rel="noreferrer"
        >
          <FaGithub />
        </a>
      )}
      {stackoverflowUrl && (
        <a
          href={stackoverflowUrl}
          target="_blank"
          className="inline-block text-blue-600"
          rel="noreferrer"
        >
          <FaStackOverflow />
        </a>
      )}
      {npmUrl && (
        <a
          href={npmUrl}
          target="_blank"
          className="inline-block text-blue-600"
          rel="noreferrer"
        >
          <ImNpm />
        </a>
      )}
      {vsmUrl && (
        <a
          href={vsmUrl}
          target="_blank"
          className="inline-block text-blue-600"
          rel="noreferrer"
        >
          <SiVisualstudiocode />
        </a>
      )}
    </div>
  </div>
)

export default ProfileCard
