import { Fragment, useEffect, useState } from "react"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import Home from "../pages/Home/Home"
import NavBar from "./components/NavBar/NavBar"
import Footer from "./components/Footer/Footer"
import CookieConsent from "./components/CookieConsent/CookieConsent"
import Contact from "../pages/Contact/Contact"
import Privacy from "../pages/legal/Privacy/Privacy"
import Terms from "../pages/legal/Terms/Terms"
import Cookie from "../pages/legal/Cookie/Cookie"
import Cancellation from "../pages/legal/Cancellation/Cancellation"
import ProductsCompliance from "../pages/legal/ProductsCompliance/ProductsCompliance"

const App = () => {
  const location = useLocation()
  const [productStats, setProductStats] = useState(undefined)

  useEffect(() => {
    if (location.state?.scrollToTop) {
      window.scrollTo(0, 0)
    }
  }, [location.state])

  return (
    <Fragment>
      <NavBar />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Helmet>
                <title>SysRestart | Software Products & Services</title>
              </Helmet>
              <Home
                productStats={productStats}
                setProductStats={setProductStats}
              />
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              <Helmet>
                <title>SysRestart | Contact Us</title>
              </Helmet>
              <Contact />
            </>
          }
        />
        <Route
          path="/privacy"
          element={
            <>
              <Helmet>
                <title>SysRestart | Privacy Policy</title>
              </Helmet>
              <Privacy />
            </>
          }
        />
        <Route
          path="/cookie"
          element={
            <>
              <Helmet>
                <title>SysRestart | Cookie Policy</title>
              </Helmet>
              <Cookie />
            </>
          }
        />
        <Route
          path="/terms"
          element={
            <>
              <Helmet>
                <title>SysRestart | Terms & Conditions</title>
              </Helmet>
              <Terms />
            </>
          }
        />
        <Route
          path="/cancellation"
          element={
            <>
              <Helmet>
                <title>SysRestart | Cancellation Policy</title>
              </Helmet>
              <Cancellation />
            </>
          }
        />
        <Route
          path="/products-compliance"
          element={
            <>
              <Helmet>
                <title>SysRestart | Products Compliance</title>
              </Helmet>
              <ProductsCompliance />
            </>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
      <CookieConsent />
    </Fragment>
  )
}

export default App
