import { Fragment, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { FaAws, FaGlobe } from "react-icons/fa"
import { IoIosAppstore } from "react-icons/io"
import { DiAndroid } from "react-icons/di"
import {
  SiApple,
  SiFirefox,
  SiGooglechrome,
  SiGooglecloud,
  SiMicrosoftedge,
  SiVisualstudiocode,
  SiWindows,
} from "react-icons/si"
import ProfileCard from "./components/ProfileCard"
import { clients, products, team } from "../../utils/config"
import { ScrollResetterLink } from "../../utils/functions"
import { getVsmProductInfo } from "../../utils/api"

const Home = ({ productStats, setProductStats }) => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.hash) {
      switch (location.hash) {
        case "#about":
          scrollToSection("aboutPane")
          break
        case "#products":
          scrollToSection("productsPane")
          break
        case "#services":
          scrollToSection("servicesPane")
          break
        case "#team":
          scrollToSection("teamPane")
          break
        case "#contact":
          scrollToSection("contactPane")
          break
        default:
          navigate("/", { replace: true })
          break
      }
    }
  }, [location.hash, navigate])

  useEffect(() => {
    ;(async () => {
      if (productStats === undefined) {
        const productInfoPromises = []
        const productNames = []
        for (const product of products) {
          const { productId, vsmUrl } = product
          if (productId) {
            productNames.push(product.name)
            if (vsmUrl) {
              productInfoPromises.push(getVsmProductInfo({ productId }))
            }
          }
        }
        const productInfoPromisesSettledValues = await Promise.allSettled(
          productInfoPromises
        )
        const newProductStats = {}
        for (const [
          i,
          settledValue,
        ] of productInfoPromisesSettledValues.entries()) {
          const productName = productNames[i]
          if (settledValue.status === "fulfilled") {
            newProductStats[productName] = settledValue.value.toLocaleString()
          } else if (settledValue.status === "rejected") {
            newProductStats[productName] = undefined
          }
        }
        setProductStats(newProductStats)
      }
    })()
  }, [productStats, setProductStats])

  const scrollToSection = (sectionId) => {
    // The setTimeout ensures that scrollIntoView works on navigating forward and back.
    setTimeout(() => {
      document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" })
    })
  }

  return (
    <Fragment>
      <div id="aboutPane" className="text-center py-20 px-10 bg-gray-300">
        <h1 className="text-3xl font-bold">
          Products & Services by
          <br />
          Seasoned Software Developers
        </h1>
        <div className="mt-10 grid grid-cols-12">
          <div className="col-span-12 lg:col-start-4 lg:col-span-6 md:col-start-3 md:col-span-8">
            <h3
              className="text-lg text-justify"
              style={{ textAlignLast: "center" }}
            >
              We are passionate software developers and productivity buffs
              solving niche software problems for users through our products and
              services
            </h3>
          </div>
        </div>
      </div>

      <div
        id="productsPane"
        className="text-center pt-16 pb-24 px-10 bg-gray-200"
      >
        <h1 className="text-3xl">Our Products</h1>
        <div className="mt-12 grid grid-cols-12 gap-3">
          {products
            .filter((product) => !product.openSource)
            .map((product, i) => (
              <div
                key={i}
                className={`col-span-12 ${
                  i === 0 ? "sm:col-span-12" : "sm:col-span-6"
                } ${i === 0 ? "lg:col-span-6" : "lg:col-span-3"} ${
                  i !== 0 && i % 2 === 0 ? "lg:col-start-7" : "lg:col-start-4"
                }`}
              >
                <ProfileCard
                  name={product.name}
                  description={product.description}
                  imgSrc={product.imgSrc}
                  imgRounded={product.imgRounded}
                  stat={productStats?.[product.name]}
                  producthuntEmbed={product.producthuntEmbed}
                  websiteUrl={product.websiteUrl}
                  vsmUrl={product.vsmUrl}
                />
              </div>
            ))}
        </div>
        <h2 className="text-2xl text-gray-700 mt-12">Open Source</h2>
        <div className="mt-12 grid grid-cols-12 gap-3">
          {products
            .filter((product) => product.openSource)
            .map((product, i) => (
              <div
                key={i}
                className={`col-span-12 sm:col-span-6 lg:col-span-3 ${
                  i % 2 === 0 ? "lg:col-start-4" : "lg:col-start-7"
                }`}
              >
                <ProfileCard
                  name={product.name}
                  description={product.description}
                  imgSrc={product.imgSrc}
                  imgRounded={product.imgRounded}
                  npmUrl={product.npmUrl}
                />
              </div>
            ))}
        </div>
      </div>

      <div
        id="servicesPane"
        className="text-center pt-20 pb-24 px-10 bg-gray-300"
      >
        <h1 className="text-3xl">Our Services</h1>
        <div className="mt-10 grid grid-cols-12">
          <div className="col-span-12 sm:col-start-4 sm:col-span-6 xl:col-start-5 xl:col-span-4">
            <p className="text-justify" style={{ textAlignLast: "center" }}>
              Alongside our products, we also offer high-quality software
              development services across a variety of verticals:
            </p>
          </div>
        </div>
        <div className="grid mt-12 grid-rows-1 grid-cols-6 md:grid-cols-12">
          <div className="h-16 col-span-2 md:col-start-4 flex flex-col justify-between items-center">
            <div className="h-12 flex flex-col justify-center">
              <FaGlobe size={30} />
            </div>
            <small className="font-bold">Web</small>
          </div>
          <div className="h-16 col-span-2 flex flex-col justify-between items-center">
            <div className="h-12 flex items-center">
              <SiGooglechrome size={29} />
              <div className="mx-1.5">|</div>
              <SiFirefox size={29} />
              <div className="mx-1.5">|</div>
              <SiMicrosoftedge size={29} />
            </div>
            <small className="font-bold">Browser</small>
          </div>
          <div className="h-16 col-span-2 flex flex-col justify-between items-center">
            <div className="h-12 flex flex-col justify-center">
              <SiVisualstudiocode size={30} />
            </div>
            <small className="font-bold">IDE</small>
          </div>
        </div>
        <div className="grid my-12 grid-rows-1 grid-cols-6 md:grid-cols-12">
          <div className="h-16 col-span-2 md:col-start-4 flex flex-col justify-between items-center">
            <div className="h-12 flex items-center">
              <IoIosAppstore size={38} />
              <div className="mx-1.5">|</div>
              <DiAndroid size={32} />
            </div>
            <small className="font-bold">Mobile</small>
          </div>
          <div className="h-16 col-span-2 flex flex-col justify-between items-center">
            <div className="h-12 flex items-center">
              <SiApple size={31} />
              <div className="mx-1.5">|</div>
              <SiWindows className="ml-0.5" size={26} />
            </div>
            <small className="font-bold">Desktop</small>
          </div>
          <div className="h-16 col-span-2 flex flex-col justify-between items-center">
            <div className="h-12 flex items-center">
              <FaAws size={37} />
              <div className="mx-1.5">|</div>
              <SiGooglecloud size={34} />
            </div>
            <small className="font-bold">Cloud</small>
          </div>
        </div>
        <div className="mt-10 grid grid-cols-12">
          <hr className="col-start-4 col-span-6 h-0.5 bg-gray-700" />
        </div>
        <h2 className="text-2xl text-gray-700 mt-10">Clients</h2>
        <div className="mt-10 grid grid-cols-12 gap-3">
          {clients.map((client, i) => (
            <div
              key={i}
              className={`col-span-12 sm:col-span-6 lg:col-span-3 ${
                i % 2 === 0 ? "lg:col-start-4" : "lg:col-start-7"
              }`}
            >
              <ProfileCard
                imgSrc={client.imgSrc}
                websiteUrl={client.websiteUrl}
                imgRounded={client.imgRounded}
              />
            </div>
          ))}
        </div>
      </div>

      <div id="teamPane" className="text-center pt-20 pb-24 px-10 bg-gray-200">
        <h1 className="text-3xl">Team</h1>
        <div className="mt-10 grid grid-cols-12 gap-3">
          {team.map((member, i) => (
            <div
              key={i}
              className={`col-span-12 sm:col-span-4 lg:col-span-2 lg:col-start-${
                4 + i * 2
              }`}
            >
              <ProfileCard
                name={member.name}
                imgSrc={member.imgSrc}
                stackoverflowUrl={member.stackoverflowUrl}
                linkedinUrl={member.linkedinUrl}
                githubUrl={member.githubUrl}
              />
            </div>
          ))}
        </div>
      </div>

      <div
        id="contactPane"
        className="text-center pt-20 pb-32 px-10 bg-gray-300"
      >
        <h1 className="text-3xl">Contact</h1>
        <div className="mt-10 grid grid-cols-12">
          <div className="col-span-12 lg:col-start-4 lg:col-span-6">
            <p>
              For any issues, queries, suggestions, feature requests, bug
              reports, etc. for any of our products, or for queries regarding
              our services:
              <br />
              <br />
              <span className="font-semibold">
                Write to us{" "}
                <ScrollResetterLink to="/contact" className="text-blue-600">
                  here
                </ScrollResetterLink>
                <br />
                or
                <br />
                Email us at{" "}
                <a
                  href="mailto:team@sysrestart.com"
                  target="_blank"
                  className="text-blue-600"
                  rel="noreferrer"
                >
                  team@sysrestart.com
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Home
