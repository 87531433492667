import brisknoteIcon from "../static/media/product-icons/bn-sr-webiste-icon.png"
import tailwindcsstuneIcon from "../static/media/product-icons/tailwindcss-tune.png"
import reacticonsautoimportIcon from "../static/media/product-icons/react-icons-auto-import.png"
import npmIcon from "../static/media/product-icons/npm.png"
import twbsLogo from "../static/media/client-logos/twbs.png"
import capitalmindLogo from "../static/media/client-logos/capitalmind.jpg"
import manicpodLogo from "../static/media/client-logos/manicpod.png"
import moneyhopLogo from "../static/media/client-logos/moneyhop.png"
import tableroneLogo from "../static/media/client-logos/tablerone.png"
import nikhilSinhaPhoto from "../static/media/profile-photos/nikhil-sinha.jpg"
import sonuKumarPhoto from "../static/media/profile-photos/sonu-kumar.jpg"
import omkarBhedePhoto from "../static/media/profile-photos/omkar-bhede.jpg"

export const legalDocsDate = "May 4, 2024"

export const products = [
  {
    name: "BriskNote",
    description: "New tab notes that sync across devices",
    imgSrc: brisknoteIcon,
    websiteUrl: "https://brisknote.com",
    producthuntEmbed: (
      <a
        href="https://www.producthunt.com/posts/brisknote?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-brisknote"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=145601&theme=light"
          alt="BriskNote - New&#0032;tab&#0032;notes&#0032;that&#0032;sync&#0032;across&#0032;devices | Product Hunt"
        />
      </a>
    ),
  },
  {
    name: "TailwindCSS Tune",
    description: "TailwindCSS class helpers inside VSCode",
    productId: "omkarbhede.tailwindcss-tune",
    imgSrc: tailwindcsstuneIcon,
    imgRounded: false,
    vsmUrl:
      "https://marketplace.visualstudio.com/items?itemName=omkarbhede.tailwindcss-tune",
  },
  {
    name: "react icons auto import",
    description: "Browse and use react-icons inside VSCode",
    productId: "omkarbhede.react-icons-auto-import",
    imgSrc: reacticonsautoimportIcon,
    imgRounded: false,
    vsmUrl:
      "https://marketplace.visualstudio.com/items?itemName=omkarbhede.react-icons-auto-import",
  },
  {
    name: "nix-couchdb-replicate",
    openSource: true,
    imgSrc: npmIcon,
    npmUrl: "https://www.npmjs.com/package/nix-couchdb-replicate",
  },
  {
    name: "nix-react-state-validate",
    openSource: true,
    imgSrc: npmIcon,
    npmUrl: "https://www.npmjs.com/package/nix-react-state-validate",
  },
]

export const clients = [
  {
    imgSrc: capitalmindLogo,
    websiteUrl: "https://capitalmind.in/",
    imgRounded: false,
  },
  {
    imgSrc: manicpodLogo,
    websiteUrl: "https://manicpod.com/",
    imgRounded: false,
  },
  {
    imgSrc: moneyhopLogo,
    websiteUrl: "https://moneyhop.co/",
    imgRounded: false,
  },
  {
    imgSrc: twbsLogo,
    websiteUrl: "https://twbs.eu",
    imgRounded: false,
  },
  {
    imgSrc: tableroneLogo,
    websiteUrl: "https://tabler.one/",
    imgRounded: false,
  },
]

export const team = [
  {
    name: "Nikhil Sinha",
    imgSrc: nikhilSinhaPhoto,
    stackoverflowUrl: "https://stackoverflow.com/users/7929984",
    linkedinUrl: "https://www.linkedin.com/in/nikhilsinha1991",
  },
  {
    name: "Sonu Kumar",
    imgSrc: sonuKumarPhoto,
    linkedinUrl: "https://www.linkedin.com/in/sonu-kumar1996",
    githubUrl: "https://github.com/skra7",
  },
  {
    name: "Omkar Bhede",
    imgSrc: omkarBhedePhoto,
    linkedinUrl: "https://www.linkedin.com/in/omkar-bhede-b82696183",
    githubUrl: "https://github.com/OmkarBhede",
  },
]

export const cookiesConfig = [
  {
    name: "sysrestart_cookie_consent",
    isConsentCookie: true,
    category: "necessary",
    provider: "sysrestart.com",
    purpose: "Saves the cookie preferences that you choose here.",
  },
  {
    name: "Google Forms cookies",
    category: "necessary",
    provider: "google.com",
    purpose: "Enable you to use the Google Form on our contact page.",
    infoLink: {
      text: "Google's Cookie Policy",
      url: "https://policies.google.com/technologies/cookies",
    },
  },
  {
    name: "Microsoft Visual Studio cookies",
    category: "necessary",
    provider: "visualstudio.com",
    purpose:
      "Enable you to view the number of installs of our Microsoft Visual Studio Marketplace products.",
    infoLink: {
      text: "Microsoft's Privacy Policy",
      url: "https://privacy.microsoft.com/en-us/privacystatement",
    },
  },
  {
    name: "_ga",
    id: "ga-1",
    category: "analytics",
    provider: "sysrestart.com",
    purpose:
      "Distinguishes you from other anonymous users on Google Analytics.",
  },
  {
    name: `_ga_${process.env.REACT_APP_GOOGLE_ANALYTICS_CONTAINER_ID}`,
    id: "ga-2",
    category: "analytics",
    provider: "sysrestart.com",
    purpose: "Persists your anonymous session on Google Analytics.",
  },
]

export const productsCompliance = [
  {
    name: "SysRestart Website",
    url: "https://sysrestart.com",
    compliant: true,
  },
  // {
  //   name: "SysRestart Docs Website",
  //   url: "https://docs.sysrestart.com",
  //   compliant: false,
  //   reason: "Uses Google Sites",
  //   isDocsWebsite: true,
  // },
  {
    name: "BriskNote",
    subProducts: [
      {
        name: "BriskNote Website",
        url: "https://brisknote.com",
        compliant: true,
      },
      {
        name: "BriskNote Chrome Extension",
        url: "https://chrome.google.com/webstore/detail/brisknote/gbdigbbnelhehnhlkhiepkpkedokgfog",
        compliant: true,
      },
      {
        name: "BriskNote Firefox Extension",
        url: "https://addons.mozilla.org/en-GB/firefox/addon/brisknote/",
        compliant: true,
      },
      {
        name: "BriskNote Edge Extension",
        url: "https://microsoftedge.microsoft.com/addons/detail/cdpmbbfogajgjilkflahadcdefmipako",
        compliant: true,
      },
      {
        name: "BriskNote Android App",
        url: "https://play.google.com/store/apps/details?id=com.brisknote",
        compliant: true,
      },
      {
        name: "BriskNote iOS App",
        url: "https://apps.apple.com/app/brisknote/id1469014542",
        compliant: true,
      },
    ],
  },
]
