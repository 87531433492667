import { Menu } from "@headlessui/react"
import { MdMenu } from "react-icons/md"
import { Link } from "react-router-dom"

const NavMenu = () => {
  return (
    <Menu as="div" className="relative">
      <Menu.Button>
        <MdMenu size={30} />
      </Menu.Button>
      <Menu.Items className="absolute right-0 py-1 rounded shadow-xl border-4 bg-gray-50 min-w-[150px]">
        <Menu.Item as="div">
          <Link to="/#products" className="inline-block w-full px-4 py-2">
            Products
          </Link>
        </Menu.Item>
        <Menu.Item as="div">
          <Link to="/#services" className="inline-block w-full px-4 py-2">
            Services
          </Link>
        </Menu.Item>
        {/* <Menu.Item as="div">
          <a
            href={
              productsCompliance.find((product) => product.isDocsWebsite).url
            }
            className="inline-block w-full px-4 py-2"
            rel="noreferrer"
          >
            Docs
          </a>
        </Menu.Item> */}
        <Menu.Item as="div">
          <Link to="/#contact" className="inline-block w-full px-4 py-2">
            Contact
          </Link>
        </Menu.Item>
      </Menu.Items>
    </Menu>
  )
}

export default NavMenu
