import { legalDocsDate } from "../../../utils/config"
import { ScrollResetterLink } from "../../../utils/functions"

const Terms = () => (
  <div className="py-20 px-10 bg-gray-100">
    <h1 className="text-center text-3xl">Terms and Conditions</h1>
    <br />
    <br />
    <div className="text-justify">
      <p>
        <b>Effective Date: {legalDocsDate}</b>
      </p>
      <br />
      <p>
        Please read these terms and conditions ("Terms") carefully before using
        our products/sub-products ("products"). By accessing or using our
        products, you agree to be bound by these Terms and the accompanying
        Privacy Policy.
      </p>
      <br />
      <p>
        <b>Acceptance of Terms</b>
      </p>
      <br />
      <p>
        These Terms constitute a legally binding agreement between you ("user,"
        "you," or "your") and SysRestart ("we," "us," "our," or "SysRestart").
        By accessing or using our products, you acknowledge that you have read,
        understood, and agree to be bound by these Terms. If you are using our
        products on behalf of an entity, you represent and warrant that you have
        the authority to bind that entity to these Terms.
      </p>
      <br />
      <p>
        <b>Use of our Products</b>
      </p>
      <br />
      <p>
        You may use our products solely for lawful purposes and in compliance
        with all applicable laws and regulations.
      </p>
      <br />
      <p>
        <b>User Accounts</b>
      </p>
      <br />
      <p>
        To access certain features of our products, you may be required to
        create a user account. You are responsible for maintaining the
        confidentiality of your account credentials and for all activities that
        occur under your account. You agree to notify us immediately of any
        unauthorized use of your account or any other breach of security. We
        reserve the right to suspend or terminate your account at any time for
        any reason at our sole discretion.
      </p>
      <br />
      <p>
        <b>Data Protection and GDPR Compliance</b>
        <br />
        <em className="text-sm">
          Applicable for{" "}
          <ScrollResetterLink
            to="/products-compliance"
            className="text-blue-600"
          >
            <u>certain products</u>
          </ScrollResetterLink>
        </em>
      </p>
      <br />
      <p>
        We are committed to protecting your personal data in accordance with the
        General Data Protection Regulation (GDPR) and other applicable data
        protection laws. Please refer to our Privacy Policy for information on
        how we collect, use, disclose, and safeguard your personal data.
      </p>
      <br />
      <p>
        <b>Intellectual Property</b>
      </p>
      <br />
      <p>
        All content, materials, and features on our products, including but not
        limited to text, graphics, logos, images, software, and the arrangement
        thereof, are owned or licensed by us and are protected by intellectual
        property laws. You may not use, reproduce, distribute, modify, or create
        derivative works of any content on our products without our prior
        written consent.
      </p>
      <br />
      <p>
        <b>Prohibited Conduct</b>
      </p>
      <br />
      <ul className="list-disc list-inside">
        <li>Violate any applicable laws or regulations.</li>
        <li>Infringe upon the intellectual property rights of others.</li>
        <li>Transmit any viruses, malware, or other harmful code.</li>
        <li>
          Interfere with the security or integrity of our products or the data
          of other users.
        </li>
        <li>Engage in any fraudulent or deceptive activities.</li>
        <li>
          Attempt to gain unauthorized access to any part of our products or its
          related systems.
        </li>
      </ul>
      <br />
      <p>
        <b>Third-Party Links</b>
      </p>
      <br />
      <p>
        Our products may contain links to third-party websites or services. We
        are not responsible for the content, privacy policies, or practices of
        such third-party sites. Your interactions with third-party websites are
        solely between you and the third party, and we disclaim any liability
        for any damages arising from such interactions.
      </p>
      <br />
      <p>
        <b>Limitation of Liability</b>
      </p>
      <br />
      <p>
        To the maximum extent permitted by law, we shall not be liable for any
        direct, indirect, incidental, special, or consequential damages arising
        out of or related to your use of our products, including but not limited
        to any loss of data, profits, or business opportunities.
      </p>
      <br />
      <p>
        <b>Modification of Terms</b>
      </p>
      <br />
      <p>
        We may update these Terms from time to time to reflect changes in our
        practices or for other operational, legal, or regulatory reasons. We
        will post the updated Terms on this page and update the "Effective Date"
        accordingly. We encourage you to review this policy periodically for any
        updates. By continuing to use our products after the updated Terms have
        been posted, you agree to be bound by the revised Terms.
      </p>
      <br />
      <p>
        <b>Governing Law and Jurisdiction</b>
      </p>
      <br />
      <p>
        These Terms shall be governed by and construed in accordance with the
        laws of Karnataka, India. Any dispute arising under or relating to these
        Terms shall be subject to the exclusive jurisdiction of the courts of
        Karnataka, India.
      </p>
      <br />
      <p>
        If you have any questions or concerns regarding these Terms, please{" "}
        <ScrollResetterLink to="/contact" className="text-blue-600">
          <u>contact us</u>
        </ScrollResetterLink>
        .
      </p>
      <br />
      <p>This document is effective as of the date mentioned above.</p>
    </div>
  </div>
)

export default Terms
