import { Link } from "react-router-dom"
import { ScrollResetterLink } from "../../../utils/functions"
import { ReactComponent as Logo } from "../../../static/media/sr-logo.svg"
import NavMenu from "./components/NavMenu"

const NavBar = () => {
  return (
    <nav className="flex justify-between py-3 px-10 h-16 sticky top-0 bg-gray-100 shadow-xl z-10">
      <ScrollResetterLink to="/" className="h-full">
        <Logo className="h-full w-auto" />
      </ScrollResetterLink>
      <div className="sm:hidden flex flex-col justify-center">
        <NavMenu />
      </div>
      <div className="hidden sm:flex items-center text-gray-600">
        <Link to="/#products">Products</Link>
        <Link to="/#services" className="ml-5">
          Services
        </Link>
        {/* <a
          href={productsCompliance.find((product) => product.isDocsWebsite).url}
          className="ml-5"
          rel="noreferrer"
        >
          Docs
        </a> */}
        <Link to="/#contact" className="ml-5">
          Contact
        </Link>
      </div>
    </nav>
  )
}

export default NavBar
