import { legalDocsDate } from "../../../utils/config"
import { ScrollResetterLink } from "../../../utils/functions"

const Cookie = () => (
  <div className="py-20 px-10 bg-gray-100">
    <h1 className="text-center text-3xl">Cookie Policy</h1>
    <br />
    <br />
    <div className="text-justify">
      <p>
        <b>Effective Date: {legalDocsDate}</b>
      </p>
      <br />
      <p>
        This Cookie Policy explains how SysRestart ("we," "us," "our," or
        "SysRestart") uses cookies on our products/sub-products ("products") in
        compliance with the General Data Protection Regulation (GDPR) and other
        applicable data protection laws.
      </p>
      <br />
      <p>
        <b>What Are Cookies?</b>
      </p>
      <br />
      <p>
        Cookies are small text files that are stored on your device when you
        visit a website. They help enhance your browsing experience by
        remembering your preferences and actions. They help us by allowing us to
        track your activity on our website to the extent allowed to us by you.
      </p>
      <br />
      <p>
        <b>Types of Cookies we Use</b>
      </p>
      <br />
      <p>
        <em>Classification based on lifecycle of the cookie</em>:
      </p>
      <ul className="list-disc list-inside">
        <li>
          Session Cookies: Temporary cookies that expire when the user closes
          their browser. They are used to maintain session data and enable
          essential site functions.
        </li>
        <li>
          Persistent Cookies: These remain on the user's device after the
          browser is closed. They are used to remember user preferences, login
          information, and analyze user behavior over time.
        </li>
      </ul>
      <br />
      <p>
        <em>Classification based on owner of the cookie</em>:
      </p>
      <ul className="list-disc list-inside">
        <li>
          First-Party Cookies: Set by the website being visited (us). They help
          the site remember your information for your next visit, enhancing user
          experience.
        </li>
        <li>
          Third-Party Cookies: A cookie that is placed on a user's device by a
          website other than the one the user is currently visiting. When you
          visit a website, and that website loads resources (like advertisements
          or images) from another domain, cookies from that external domain are
          considered third-party cookies. Third-party cookies are commonly used
          for purposes of advertising. These third parties may have their own
          cookie policies.
        </li>
      </ul>
      <br />
      <p>
        <em>Classification based on utility of the cookie</em>:
      </p>
      <ul className="list-disc list-inside">
        <li>
          Functionality Cookies: These cookies enhance the usability and
          functionality of a website. They are often used to preserve your user
          session for your next visit to the website, and also remember choices
          you make (such as language preferences) to provide a more personalized
          experience.
        </li>
        <li>
          Performance or Analytical Cookies: These cookies collect data about
          how visitors use a website, such as page loads, clicks, scrolls, etc.
          The information gathered is used to improve the website and optimize
          user experience.
        </li>
      </ul>
      <br />
      <p>
        <b>Cookies Info & Management</b>
        <br />
        <em className="text-sm">
          Applicable for{" "}
          <ScrollResetterLink
            to="/products-compliance"
            className="text-blue-600"
          >
            <u>certain products</u>
          </ScrollResetterLink>
        </em>
      </p>
      <br />
      <p>
        Information on what cookies a particular one of our products uses and
        their specific purposes pertaining to that product is made available to
        you in a dialog which can be accessed from a link called "Cookie
        Preferences" in the navigation area in each one of our product webpages.
        It is in this dialog that you can customize your cookie preferences for
        that particular product.
      </p>
      <br />
      <p>
        You are also nudged to set your cookie preferences via an alert message
        when you visit the webpage of the product the first time. This alert
        message remains shown to you until you make a choice.
      </p>
      <br />
      <p>
        You also get more finer-grained management of your cookies in your
        browser settings.
      </p>
      <br />
      <p>
        <b>Changes to the Cookie Policy</b>
      </p>
      <br />
      <p>
        We may update this policy from time to time to reflect changes in our
        practices or for other operational, legal, or regulatory reasons. We
        will post the updated policy on this page and update the "Effective
        Date" accordingly. We encourage you to review this policy periodically
        for any updates.
      </p>
      <br />
      <p>
        <b>Contact Us</b>
      </p>
      <br />
      <p>
        If you have any questions or concerns regarding this cookie policy,
        please{" "}
        <ScrollResetterLink to="/contact" className="text-blue-600">
          <u>contact us</u>
        </ScrollResetterLink>
        .
      </p>
      <br />
      <p>
        This policy is effective as of the date mentioned above and is governed
        by the laws of Karnataka, India.
      </p>
    </div>
  </div>
)

export default Cookie
