import { legalDocsDate } from "../../../utils/config"
import { ScrollResetterLink } from "../../../utils/functions"

const Cancellation = () => (
  <div className="py-20 px-10 bg-gray-100">
    <h1 className="text-center text-3xl">Cancellation Policy</h1>
    <br />
    <br />
    <div className="text-justify">
      <p>
        <b>Effective Date: {legalDocsDate}</b>
      </p>
      <br />
      <p>
        Thank you for using the services offered by SysRestart ("we," "us,"
        "our," or "SysRestart") through our products/sub-products ("products").
        We are committed to providing you with a satisfactory experience. This
        policy outlines the rules and procedures for cancelling your
        subscription for one of our products.
      </p>
      <br />
      <p>
        <b>Subscription Cancellation Process</b>
      </p>
      <br />
      <p>
        If you wish to cancel your subscription with us, you may do so by
        following the instructions provided in the subscription section of your
        account on our products. Upon cancellation, your subscription will be
        terminated at the end of the current billing cycle, and you will not be
        charged for any subsequent periods.
      </p>
      <br />
      <p>
        <b>Termination by Us</b>
      </p>
      <br />
      <p>
        We reserve the right to terminate your subscription if we determine that
        you have violated our Terms and Conditions.
      </p>
      <br />
      <p>
        <b>Changes to the Cancellation Policy</b>
      </p>
      <br />
      <p>
        We may update this policy from time to time to reflect changes in our
        practices or for other operational, legal, or regulatory reasons. We
        will post the updated policy on this page and update the "Effective
        Date" accordingly. We encourage you to review this policy periodically
        for any updates.
      </p>
      <br />
      <p>
        <b>Contact Us</b>
      </p>
      <br />
      <p>
        If you have any questions or concerns regarding this cancellation
        policy, please{" "}
        <ScrollResetterLink to="/contact" className="text-blue-600">
          <u>contact us</u>
        </ScrollResetterLink>
        .
      </p>
      <br />
      <p>
        This policy is effective as of the date mentioned above and is governed
        by the laws of Karnataka, India.
      </p>
    </div>
  </div>
)

export default Cancellation
