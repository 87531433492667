export const getVsmProductInfo = async ({ productId }) => {
  try {
    const response = await fetch(
      "https://marketplace.visualstudio.com/_apis/public/gallery/extensionquery?api-version=7.2-preview.1",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "omit", // This is to prevent setting of a cookie called VstsSession sent by VS Marketplace in the Set-Cookie header of the response. This does not always work, therefore, we've declared cookies from visualstudio.com as necessary cookies in cookie consent.
        body: JSON.stringify({
          filters: [
            {
              criteria: [
                {
                  filterType: 7,
                  value: productId,
                },
              ],
            },
          ],
          flags: 256,
        }),
      }
    )
    const data = await response.json()
    const stats = data?.results?.[0]?.extensions?.[0]?.statistics
    const noOfInstalls = stats?.find(
      (item) => item.statisticName === "install"
    )?.value
    return noOfInstalls
  } catch (err) {
    throw err
  }
}
