import { Link } from "react-router-dom"
import { ScrollResetterLink } from "../../../utils/functions"
import { ReactComponent as Logo } from "../../../static/media/sr-logo.svg"

const Footer = () => (
  <nav className="flex flex-col text-center px-10 py-12 bg-gray-900 text-gray-50 sm:flex-row sm:justify-between">
    <div className="sm:text-left">
      <h3 className="font-bold text-gray-400">Info</h3>
      <div className="underline flex flex-col mt-1">
        <Link to="/#about">About</Link>
        <Link to="/#products">Products</Link>
        <Link to="/#services">Services</Link>
        <Link to="/#team">Team</Link>
        <a href="https://mail.sysrestart.com" rel="noreferrer">
          Mail
        </a>
        {/* <a
          href={productsCompliance.find((product) => product.isDocsWebsite).url}
          rel="noreferrer"
        >
          Docs
        </a> */}
      </div>
    </div>
    <div className="mt-6 sm:mt-0 sm:text-left">
      <h3 className="font-bold text-gray-400">Legal</h3>
      <div className="underline flex flex-col mt-1">
        <button className="sm:text-left" data-cc="c-settings">
          Cookie Preferences
        </button>
        <ScrollResetterLink to="/privacy">Privacy Policy</ScrollResetterLink>
        <ScrollResetterLink to="/cookie">Cookie Policy</ScrollResetterLink>
        <ScrollResetterLink to="/terms">Terms & Conditions</ScrollResetterLink>
        <ScrollResetterLink to="/products-compliance">
          Products Compliance
        </ScrollResetterLink>
        <ScrollResetterLink to="/cancellation">
          Cancellation Policy
        </ScrollResetterLink>
      </div>
    </div>
    <div className="mt-8 sm:mt-0 sm:text-right">
      <div className="flex font-light justify-center sm:justify-end">
        <p>&copy; {new Date().getFullYear()}&nbsp;</p>
        <div className="h-5 mt-1">
          <Logo className="h-full w-auto" />
        </div>
      </div>
      <p className="text-xs mt-2">
        SysRestart is a registered
        <br />
        proprietorship of India
      </p>
      <div className="underline mt-2">
        <ScrollResetterLink to="/contact">Contact Us</ScrollResetterLink>
      </div>
    </div>
  </nav>
)

export default Footer
